/** @jsx jsx */

import { jsx, Text, Styled } from "theme-ui"

import Link from "@components/link"

export default ({ posts, detailed }) => (
  <Styled.ul sx={{ m: 0, p: 0 }}>
    {posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug

      return (
        <li
          key={node.fields.slug}
          sx={{
            display: "grid",
            gridGap: 4, // theme.space[4]
            gridTemplateColumns: ["140px auto"],
          }}
        >
          <Text sx={{ pr: 4 }}>{node.frontmatter.date}</Text>
          <div>
            <Link to={node.fields.slug} sx={{ m: 0, p: 0 }}>
              {title}
            </Link>
            {detailed && (
              <Styled.p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            )}
          </div>
        </li>
      )
    })}
  </Styled.ul>
)
