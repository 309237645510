/** @jsx jsx */

import { graphql } from "gatsby"

import { jsx, Styled } from "theme-ui"

import Root from "@layouts/root"
import SEO from "@components/seo"
import PostList from "@components/post-list"

const Journal = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Root location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Styled.h1>Journal</Styled.h1>
      <PostList posts={posts} detailed />
    </Root>
  )
}

export default Journal

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD.MM.YY")
          }
        }
      }
    }
  }
`
